<template>
  <div>
    <v-snackbar v-model="snackbar" color="success" top :timeout="5000">
      <v-row>
        Your email was sent successfully
        <v-spacer></v-spacer>
        <v-btn dark icon @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-snackbar>

    <transition-group name="list" tag="div">
      <v-card
        v-for="flight in flights"
        :key="flight.id"
        class="mb-10 rounded-lg"
        outlined
      >
        <div
          v-if="flight.withAlternateDate"
          style="background-color: #0ec50e; width: 100%; color: white"
        >
          <v-avatar left>
            <v-icon color="white">mdi-airplane-clock</v-icon>
          </v-avatar>
          Alternate Date
        </div>
        <div
          v-if="flight.mailOnlyFare"
          style="background-color: #2f80c2; width: 100%; color: white"
        >
          <v-avatar left>
            <v-icon color="white">mdi-account-check</v-icon>
          </v-avatar>
          Special flight for Adam travel members
        </div>

        <v-row justify="space-between" align="center" class="my-2 pl-2">
          <v-col cols="12" md="8">
            <v-row
              justify="start"
              align="center"
              v-for="(flightSegment, i) in flight.flightSegments"
              :key="i"
            >
              <v-col
                cols="12"
                md="5"
                v-if="!flight.mailOnlyFare"
                class="d-flex align-start"
              >
                <img
                  :src="flightSegment.Segments[0].MarketingAirlineLogoSM"
                  height="30"
                />
                <div class="ml-5">
                  <v-tooltip bottom color="blueDark darken-1">
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="airline font-weight-medium greyText--text">{{
                        flightSegment.Segments[0].MarketingAirlineName
                      }}</span>
                    </template>
                    <span>{{ flightSegment.Segments[0].MarketingAirlineName }}</span>
                  </v-tooltip>
                  <p class="mb-0 caption grey--text">
                    Operated by {{ flightSegment.Segments[0].OperatingAirlineName }}
                  </p>
                  <v-subheader class="orange--text d-inline-flex px-0">{{
                    getThisDate(flightSegment.Segments[0].DepartureDate)
                  }}</v-subheader>
                  -
                  <v-subheader class="orange--text d-inline-flex px-0">{{
                    getThisDate(
                      flightSegment.Segments[flightSegment.Segments.length - 1]
                        .ArrivalDate
                    )
                  }}</v-subheader>
                </div>
                <!-- <span class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</span> -->
              </v-col>
              <v-col cols="12" md="4" class="pa-5" v-else>
                <v-row>
                  <!-- <v-icon color="secondary" class="mx-5">mdi-email-check</v-icon> -->
                  <v-icon color="grey lighten-1">mdi-shield-airplane</v-icon>
                </v-row>
                <p class="body-2 mt-5 mb-0">ATN members</p>
              </v-col>
              <v-col cols="12" md="6" class="px-5">
                <div class="d-flex justify-space-between align-center">
                  <div class="airline">
                    <p class="airline font-weight-bold greyText--text text-right">
                      <v-icon large color="airline greyText--text"
                        >mdi-airplane-takeoff</v-icon
                      >
                      {{ flightSegment.Segments[0].DepartureTime }}
                      <br />
                      {{ flightSegment.Segments[0].OriginLocationCode }}
                    </p>
                  </div>
                  <v-tooltip bottom nudge-top="30" color="blueDark darken-1">
                    <template v-slot:activator="{ on }">
                      <p v-on="on" class="body-2 orange--text">
                        {{ flightSegment.stops }}Stops
                      </p>
                    </template>
                    <span>
                      <p v-for="n in flightSegment.stops" :key="n">
                        {{
                          flightSegment.Segments[n - 1].DestinationLocation.split(",")[0]
                        }}
                        - <span>{{ flightSegment.Segments[n - 1].Duration }}h</span>
                      </p>
                    </span>
                  </v-tooltip>
                  <div>
                    <p class="airline font-weight-bold greyText--text text-right">
                      <v-icon large color="airline greyText--text"
                        >mdi-airplane-landing</v-icon
                      >
                      {{
                        flightSegment.Segments[flightSegment.Segments.length - 1]
                          .ArrivalTime
                      }}
                      <br />
                      {{
                        flightSegment.Segments[flightSegment.Segments.length - 1]
                          .DestinationLocationCode
                      }}
                    </p>
                  </div>
                </div>
                <!-- </div> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" class="price text-center py-0">
            <div>
              <p
                v-if="!flight.pricingInfo.newFare > 0"
                class="font-weight-black orange--text text-h6 text-center"
              >
                {{
                  flight.pricingInfo.TotalFare.toFixed(2)
                    | changeCurrency(flight.pricingInfo.TotalFare.toFixed(2))
                }}
              </p>
              <div v-else>
                <p class="body-2 text-center">
                  {{
                    flight.pricingInfo.TotalFare.toFixed(2)
                      | changeCurrency(flight.pricingInfo.TotalFare.toFixed(2))
                  }}
                </p>
                <p class="body-1 font-weight-black text-center">
                  - {{ flight.pricingInfo.discount_amount }}
                </p>
                <v-divider></v-divider>
                <p class="font-weight-black orange--text text-h6 text-center">
                  {{
                    flight.pricingInfo.newFare.toFixed(2)
                      | changeCurrency(flight.pricingInfo.newFare.toFixed(2))
                  }}
                </p>
              </div>
              <p class="caption text-center grey--text lighten-1 my-0">
                {{ flight.pricingInfo.totalFareNote }}
              </p>
              <br />
              <p
                v-if="flight.pricingInfo.premiumNote"
                class="caption success--text text-center lighten-1 my-0"
              >
                {{ flight.pricingInfo.premiumNote }}
              </p>
              <v-btn
                v-if="!flight.mailOnlyFare && $route.name !== 'book'"
                color="orange"
                :name="`${flight.netFlight ? 'Net Flight' : ''}`"
                elevation="0"
                x-large
                @click="book(flight.id)"
                class="d-block mx-auto white--text rounded-lg px-10"
                >View Details</v-btn
              >
              <v-btn
                v-if="flight.mailOnlyFare && !$cookies.isKey('userToken')"
                color="orange"
                tile
                @click="$router.push('/login')"
                class="my-0 white--text"
              >
                Login to access
              </v-btn>
              <p v-if="flight.mailOnlyFare && $cookies.isKey('userToken')" class="my-0">
                Flight will be available once your account is approved
              </p>
              <!-- <v-btn v-if="flight.flightsWithSamePrice.length > 0 && !flight.mailOnlyFare" color="orange" text @click="openSamePriceFlightsDialog(flight.flightsWithSamePrice, flight.id)" class="my-0 white--text">More flights at this price</v-btn>
                        <v-btn v-if="flight.mailOnlyFare" color="orange" tile @click="showBookingDialog = true; flightId = flight.id" class="my-0 white--text">Send Email to book</v-btn>
                        <v-btn
                        text
                        small
                        color="blue"
                        @click="expand(flight.id)"
                        v-if="!flight.mailOnlyFare"
                        class="mt-2"
                        >
                            More Details
                            <v-icon right>{{ show && currentFlight === flight.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn> -->
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            small
            color="blue"
            @click="expand(flight.id)"
            v-if="!flight.mailOnlyFare"
          >
            More Details
            <v-icon right>{{
              show && currentFlight === flight.id ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
          <v-spacer
            v-if="flight.mailOnlyFare || flight.flightsWithSamePrice.length > 0"
          ></v-spacer>
          <v-btn
            v-if="flight.flightsWithSamePrice.length > 0 && !flight.mailOnlyFare"
            text
            color="orange"
            @click="openSamePriceFlightsDialog(flight.flightsWithSamePrice, flight.id)"
            class="my-0 white--text"
            >More flights at this price</v-btn
          >
          <!-- <v-btn
            v-if="flight.mailOnlyFare"
            color="orange"
            tile
            @click="
              showBookingDialog = true;
              flightId = flight.id;
            "
            class="my-0 white--text"
            >Send Email to book</v-btn
          > -->
        </v-card-actions>
        <v-expand-transition>
          <div v-show="show && currentFlight === flight.id">
            <v-divider></v-divider>
            <v-container>
              <div v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                <v-row v-if="i === 0" justify="space-between" class="pa-3">
                  <h3 class="body-1 font-weight-bold primary--text">
                    Depart
                    <span class="caption grey--text"
                      >{{ getThisDate(flightSegment.Segments[0].DepartureDate) }} /
                      {{ flightSegment.TotalDuration }} hours</span
                    >
                  </h3>
                  <v-btn
                    color="secondary"
                    v-if="flightSegment.baggage_rules && $route.name !== 'book'"
                    tile
                    small
                    @click="
                      baggageDialog = true;
                      baggageRules = flightSegment.baggage_rules;
                    "
                  >
                    <v-icon left>mdi-bag-checked</v-icon> Baggage Rules
                  </v-btn>
                </v-row>
                <v-row v-else justify="space-between" class="pa-3">
                  <h3 class="body-1 font-weight-bold primary--text">
                    Return
                    <span class="caption grey--text"
                      >{{ getThisDate(flightSegment.Segments[0].DepartureDate) }} /
                      {{ flightSegment.TotalDuration }} hours</span
                    >
                  </h3>
                  <v-btn
                    color="secondary"
                    v-if="flightSegment.baggage_rules && $route.name !== 'book'"
                    text
                    small
                    @click="
                      baggageDialog = true;
                      baggageRules = flightSegment.baggage_rules;
                    "
                  >
                    <v-icon left>mdi-bag-checked</v-icon> Baggage Rules
                  </v-btn>
                </v-row>
                <v-row v-for="(segment, n) in flightSegment.Segments" :key="n">
                  <v-col cols="10" sm="3">
                    <v-row>
                      <v-col cols="4">
                        <img :src="segment.MarketingAirlineLogoSM" height="30" />
                      </v-col>
                      <v-col cols="8">
                        <v-tooltip top color="blueDark darken-1">
                          <template v-slot:activator="{ on }">
                            <p v-on="on" class="body-2">
                              {{ segment.MarketingAirlineName.substr(0, 6) }}
                            </p>
                          </template>
                          <span>{{ segment.MarketingAirlineName }}</span>
                        </v-tooltip>
                        <p class="caption">
                          Operated by {{ flightSegment.Segments[0].OperatingAirlineName }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2" class="d-block d-sm-none">
                    <p class="caption">
                      <v-icon color="secondary">mdi-timer-outline</v-icon>
                      {{ segment.Duration }} hours
                    </p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <p class="body-2">{{ getThisDate(segment.DepartureDate) }}</p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <p class="body-2">{{ segment.DepartureTime }}</p>
                    <p class="body-2">
                      {{
                        segment.OriginLocation.substr(
                          0,
                          segment.OriginLocation.indexOf(",")
                        )
                      }},{{ segment.OriginLocationCode }}
                    </p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <p class="body-2">{{ segment.ArrivalTime }}</p>
                    <p class="body-2">
                      {{
                        segment.DestinationLocation.substr(
                          0,
                          segment.DestinationLocation.indexOf(",")
                        )
                      }},{{ segment.DestinationLocationCode }}
                    </p>
                  </v-col>
                  <v-col cols="3" sm="2">
                    <p class="body-2">{{ getThisDate(segment.ArrivalDate) }}</p>
                  </v-col>
                  <v-col cols="12" sm="1" class="d-none d-sm-block">
                    <p class="caption">
                      <v-icon color="secondary">mdi-timer-outline</v-icon>
                      {{ segment.Duration }} hours
                    </p>
                  </v-col>
                  <v-col cols="12" class="py-0 pb-5 text-right">
                    <v-row no-gutters justify="space-around">
                      <small>
                        <v-icon small>mdi-airplane</v-icon> {{ segment.aircraft }} /
                        Flight {{ segment.FlightNumber }}
                      </small>
                      <small class="secondary--text"
                        >Booking Class ({{ segment.BookingClass }})</small
                      >
                      <small
                        v-if="segment.classCabin"
                        class="secondary--text text-capitalize"
                        >Cabin Class ({{ segment.classCabin }})</small
                      >
                      <small
                        v-if="segment.departureTerminal"
                        class="secondary--text text-capitalize"
                        >Departure Terminal ({{ segment.departureTerminal }})</small
                      >
                      <small
                        v-if="segment.arrivalTerminal"
                        class="secondary--text text-capitalize"
                        >Arrival Terminal ({{ segment.arrivalTerminal }})</small
                      >
                    </v-row>
                    <v-divider
                      v-if="n + 1 < flightSegment.Segments.length"
                      class="mt-5"
                    ></v-divider>
                    <div
                      class="layover caption"
                      v-if="n + 1 < flightSegment.Segments.length"
                    >
                      Layover {{ flightSegment.stopsDuration[n].text }} h
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </v-expand-transition>
      </v-card>
    </transition-group>
    <v-dialog max-width="700" v-model="baggageDialog">
      <v-card>
        <v-card-title
          class="headline blueDark white--text font-weight-regular"
          primary-title
        >
          <span
            >{{ baggageRules.airlineName }},
            <sub class="caption">{{ baggageRules.airlineCode }} </sub> Baggage Rules</span
          >
        </v-card-title>
        <v-card-text v-if="baggageRules" class="text-right mt-5">
          <a
            class="my-3"
            v-if="baggageRules.baggage_url && baggageRules.baggage_url !== ''"
            target="_blank"
            :href="baggageRules.baggage_url"
            >Show All Baggage Rules</a
          >
          <div v-if="baggageRules.freePieces">
            <p class="headline text-center secondary--text">Free Pieces</p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Number Of Pieces</th>
                    <th class="text-left">Weight</th>
                    <th class="text-left">Size</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">
                      {{ baggageRules.freePieces.numberOfPieces }}
                    </td>
                    <td class="text-left">{{ baggageRules.freePieces.weight }}</td>
                    <td class="text-left">{{ baggageRules.freePieces.size }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div v-if="baggageRules.additionalCost">
            <v-divider class="mt-2 mb-5"></v-divider>
            <p class="headline text-center secondary--text">Additional Cost</p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Fees</th>
                    <th class="text-left">Weight</th>
                    <th class="text-left">Size</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">
                      {{ baggageRules.additionalCost.feesCurrency }}
                      {{ baggageRules.additionalCost.fees }}
                    </td>
                    <td class="text-left">{{ baggageRules.additionalCost.weight }}</td>
                    <td class="text-left">{{ baggageRules.additionalCost.size }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showBookingDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '90%' : '80%'"
    >
      <mailForm
        @success="snackbar = true"
        @close="showBookingDialog = false"
        :flightId="flightId"
      ></mailForm>
    </v-dialog>
    <v-dialog v-model="flightsWithSamePriceDialog" width="80%">
      <v-sheet color="white" class="pa-2">
        <div class="headline blueDark--text d-flex justify-space-between">
          <p><v-icon color="blueDark">mdi-airplane</v-icon> More Flights At Same Price</p>
          <v-btn icon color="blueDark" @click="flightsWithSamePriceDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card
          v-for="flight in samePriceFlights.flights"
          :key="flight.id"
          class="my-5 rounded-lg"
          outlined
        >
          <div
            v-if="flight.withAlternateDate"
            style="background-color: #0ec50e; width: 100%; color: white"
          >
            <v-avatar left>
              <v-icon color="white">mdi-airplane-clock</v-icon>
            </v-avatar>
            Alternate Date
          </div>
          <div
            v-if="flight.mailOnlyFare"
            style="background-color: #2f80c2; width: 100%; color: white"
          >
            <v-avatar left>
              <v-icon color="white">mdi-account-check</v-icon>
            </v-avatar>
            Special flight for Adam travel members
          </div>

          <v-row justify="space-between" align="center" class="my-2 pl-2">
            <v-col cols="12" md="8">
              <v-row
                justify="start"
                align="center"
                v-for="(flightSegment, i) in flight.flightSegments"
                :key="i"
              >
                <v-col
                  cols="12"
                  md="5"
                  v-if="!flight.mailOnlyFare"
                  class="d-flex align-start"
                >
                  <img
                    :src="flightSegment.Segments[0].MarketingAirlineLogoSM"
                    height="30"
                  />
                  <div class="ml-5">
                    <v-tooltip bottom color="blueDark darken-1">
                      <template v-slot:activator="{ on }">
                        <span
                          v-on="on"
                          class="airline font-weight-medium greyText--text"
                          >{{ flightSegment.Segments[0].MarketingAirlineName }}</span
                        >
                      </template>
                      <span>{{ flightSegment.Segments[0].MarketingAirlineName }}</span>
                    </v-tooltip>
                    <p class="mb-0 caption grey--text">
                      Operated by {{ flightSegment.Segments[0].OperatingAirlineName }}
                    </p>
                    <v-subheader class="orange--text d-inline-flex px-0">{{
                      getThisDate(flightSegment.Segments[0].DepartureDate)
                    }}</v-subheader>
                    -
                    <v-subheader class="orange--text d-inline-flex px-0">{{
                      getThisDate(
                        flightSegment.Segments[flightSegment.Segments.length - 1]
                          .ArrivalDate
                      )
                    }}</v-subheader>
                  </div>
                  <!-- <span class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</span> -->
                </v-col>
                <v-col cols="12" md="4" class="pa-5" v-else>
                  <v-row>
                    <v-icon color="secondary" class="mx-5">mdi-email-check</v-icon>
                    <v-icon color="grey lighten-1">mdi-shield-airplane</v-icon>
                  </v-row>
                  <p class="body-2 mt-5 mb-0">email-only-fare</p>
                </v-col>
                <v-col cols="12" md="6" class="px-5">
                  <div class="d-flex justify-space-between align-center">
                    <div class="airline">
                      <p class="airline font-weight-bold greyText--text text-right">
                        <v-icon large color="airline greyText--text"
                          >mdi-airplane-takeoff</v-icon
                        >
                        {{ flightSegment.Segments[0].DepartureTime }}
                        <br />
                        {{ flightSegment.Segments[0].OriginLocationCode }}
                      </p>
                    </div>
                    <v-tooltip bottom nudge-top="30" color="blueDark darken-1">
                      <template v-slot:activator="{ on }">
                        <p v-on="on" class="body-2 orange--text">
                          {{ flightSegment.stops }}Stops
                        </p>
                      </template>
                      <span>
                        <p v-for="n in flightSegment.stops" :key="n">
                          {{
                            flightSegment.Segments[n - 1].DestinationLocation.split(
                              ","
                            )[0]
                          }}
                          - <span>{{ flightSegment.Segments[n - 1].Duration }}h</span>
                        </p>
                      </span>
                    </v-tooltip>
                    <div>
                      <p class="airline font-weight-bold greyText--text text-right">
                        <v-icon large color="airline greyText--text"
                          >mdi-airplane-landing</v-icon
                        >
                        {{
                          flightSegment.Segments[flightSegment.Segments.length - 1]
                            .ArrivalTime
                        }}
                        <br />
                        {{
                          flightSegment.Segments[flightSegment.Segments.length - 1]
                            .DestinationLocationCode
                        }}
                      </p>
                    </div>
                  </div>
                  <!-- </div> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="price text-center py-0">
              <div>
                <p
                  v-if="!flight.pricingInfo.newFare > 0"
                  class="font-weight-black orange--text text-h6 text-center"
                >
                  {{
                    flight.pricingInfo.TotalFare.toFixed(2)
                      | changeCurrency(flight.pricingInfo.TotalFare.toFixed(2))
                  }}
                </p>
                <div v-else>
                  <p class="body-2 text-center">
                    {{
                      flight.pricingInfo.TotalFare.toFixed(2)
                        | changeCurrency(flight.pricingInfo.TotalFare.toFixed(2))
                    }}
                  </p>
                  <p class="body-1 font-weight-black text-center">
                    -
                    {{
                      flight.pricingInfo.discount_amount
                        | changeCurrency(flight.pricingInfo.discount_amount)
                    }}
                  </p>
                  <v-divider></v-divider>
                  <p class="font-weight-black orange--text text-h6 text-center">
                    {{
                      flight.pricingInfo.newFare.toFixed(2)
                        | changeCurrency(flight.pricingInfo.newFare.toFixed(2))
                    }}
                  </p>
                </div>
                <p class="caption text-center grey--text lighten-1 my-0">
                  {{ flight.pricingInfo.totalFareNote }}
                </p>
                <br />
                <p
                  v-if="flight.pricingInfo.premiumNote"
                  class="caption success--text text-center lighten-1 my-0"
                >
                  {{ flight.pricingInfo.premiumNote }}
                </p>
                <v-btn
                  v-if="!flight.mailOnlyFare && $route.name !== 'book'"
                  color="orange"
                  :name="`${flight.netFlight ? 'Net Flight' : ''}`"
                  elevation="0"
                  x-large
                  @click="book(flight.id, samePriceFlights.relatedFlightId)"
                  class="d-block mx-auto white--text rounded-lg px-10"
                  >View Details</v-btn
                >
                <!-- <v-btn v-if="flight.flightsWithSamePrice.length > 0 && !flight.mailOnlyFare" color="orange" text @click="openSamePriceFlightsDialog(flight.flightsWithSamePrice, flight.id)" class="my-0 white--text">More flights at this price</v-btn>
                            <v-btn v-if="flight.mailOnlyFare" color="orange" tile @click="showBookingDialog = true; flightId = flight.id" class="my-0 white--text">Send Email to book</v-btn>
                            <v-btn
                            text
                            small
                            color="blue"
                            @click="expand(flight.id)"
                            v-if="!flight.mailOnlyFare"
                            class="mt-2"
                            >
                                More Details
                                <v-icon right>{{ show && currentFlight === flight.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn> -->
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              small
              color="blue"
              @click="expand(flight.id)"
              v-if="!flight.mailOnlyFare"
            >
              More Details
              <v-icon right>{{
                show && currentFlight === flight.id
                  ? "mdi-chevron-up"
                  : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
            <v-spacer
              v-if="flight.mailOnlyFare || flight.flightsWithSamePrice.length > 0"
            ></v-spacer>
            <div>
              <v-btn
                v-if="flight.mailOnlyFare && !$cookies.isKey('userToken')"
                color="orange"
                tile
                @click="$router.push('/login')"
                class="my-0 white--text"
              >
                Login to access
              </v-btn>
              <p v-if="flight.mailOnlyFare && $cookies.isKey('userToken')" class="my-0">
                Flight will be available once your account is approved
              </p>
            </div>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show && currentFlight === flight.id">
              <v-divider></v-divider>
              <v-container>
                <div v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                  <v-row v-if="i === 0" justify="space-between" class="pa-3">
                    <h3 class="body-1 font-weight-bold primary--text">
                      Depart
                      <span class="caption grey--text"
                        >{{ getThisDate(flightSegment.Segments[0].DepartureDate) }} /
                        {{ flightSegment.TotalDuration }} hours</span
                      >
                    </h3>
                    <v-btn
                      color="secondary"
                      v-if="flightSegment.baggage_rules && $route.name !== 'book'"
                      tile
                      small
                      @click="
                        baggageDialog = true;
                        baggageRules = flightSegment.baggage_rules;
                      "
                    >
                      <v-icon left>mdi-bag-checked</v-icon> Baggage Rules
                    </v-btn>
                  </v-row>
                  <v-row v-else justify="space-between" class="pa-3">
                    <h3 class="body-1 font-weight-bold primary--text">
                      Return
                      <span class="caption grey--text"
                        >{{ getThisDate(flightSegment.Segments[0].DepartureDate) }} /
                        {{ flightSegment.TotalDuration }} hours</span
                      >
                    </h3>
                    <v-btn
                      color="secondary"
                      v-if="flightSegment.baggage_rules && $route.name !== 'book'"
                      text
                      small
                      @click="
                        baggageDialog = true;
                        baggageRules = flightSegment.baggage_rules;
                      "
                    >
                      <v-icon left>mdi-bag-checked</v-icon> Baggage Rules
                    </v-btn>
                  </v-row>
                  <v-row v-for="(segment, n) in flightSegment.Segments" :key="n">
                    <v-col cols="10" sm="3">
                      <v-row>
                        <v-col cols="4">
                          <img :src="segment.MarketingAirlineLogoSM" height="30" />
                        </v-col>
                        <v-col cols="8">
                          <v-tooltip top color="blueDark darken-1">
                            <template v-slot:activator="{ on }">
                              <p v-on="on" class="body-2">
                                {{ segment.MarketingAirlineName.substr(0, 6) }}
                              </p>
                            </template>
                            <span>{{ segment.MarketingAirlineName }}</span>
                          </v-tooltip>
                          <p class="caption">
                            Operated by
                            {{ flightSegment.Segments[0].OperatingAirlineName }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="d-block d-sm-none">
                      <p class="caption">
                        <v-icon color="secondary">mdi-timer-outline</v-icon>
                        {{ segment.Duration }} hours
                      </p>
                    </v-col>
                    <v-col cols="3" sm="2">
                      <p class="body-2">{{ getThisDate(segment.DepartureDate) }}</p>
                    </v-col>
                    <v-col cols="3" sm="2">
                      <p class="body-2">{{ segment.DepartureTime }}</p>
                      <p class="body-2">
                        {{
                          segment.OriginLocation.substr(
                            0,
                            segment.OriginLocation.indexOf(",")
                          )
                        }},{{ segment.OriginLocationCode }}
                      </p>
                    </v-col>
                    <v-col cols="3" sm="2">
                      <p class="body-2">{{ segment.ArrivalTime }}</p>
                      <p class="body-2">
                        {{
                          segment.DestinationLocation.substr(
                            0,
                            segment.DestinationLocation.indexOf(",")
                          )
                        }},{{ segment.DestinationLocationCode }}
                      </p>
                    </v-col>
                    <v-col cols="3" sm="2">
                      <p class="body-2">{{ getThisDate(segment.ArrivalDate) }}</p>
                    </v-col>
                    <v-col cols="12" sm="1" class="d-none d-sm-block">
                      <p class="caption">
                        <v-icon color="secondary">mdi-timer-outline</v-icon>
                        {{ segment.Duration }} hours
                      </p>
                    </v-col>
                    <v-col cols="12" class="py-0 pb-5 text-right">
                      <v-row no-gutters justify="space-around">
                        <small>
                          <v-icon small>mdi-airplane</v-icon> {{ segment.aircraft }} /
                          Flight {{ segment.FlightNumber }}
                        </small>
                        <small class="secondary--text"
                          >Booking Class ({{ segment.BookingClass }})</small
                        >
                        <small
                          v-if="segment.classCabin"
                          class="secondary--text text-capitalize"
                          >Cabin Class ({{ segment.classCabin }})</small
                        >
                        <small
                          v-if="segment.departureTerminal"
                          class="secondary--text text-capitalize"
                          >Departure Terminal ({{ segment.departureTerminal }})</small
                        >
                        <small
                          v-if="segment.arrivalTerminal"
                          class="secondary--text text-capitalize"
                          >Arrival Terminal ({{ segment.arrivalTerminal }})</small
                        >
                      </v-row>
                      <v-divider
                        v-if="n + 1 < flightSegment.Segments.length"
                        class="mt-5"
                      ></v-divider>
                      <div
                        class="layover caption"
                        v-if="n + 1 < flightSegment.Segments.length"
                      >
                        Layover {{ flightSegment.stopsDuration[n].text }} h
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </div>
          </v-expand-transition>
        </v-card>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import mailForm from './sendMailForm.vue'

export default {
  props: ['flights', 'type'],
  components: {
    mailForm
  },
  data () {
    return {
      show: false,
      currentFlight: null,
      baggageDialog: false,
      baggageRules: [],
      showBookingDialog: false,
      flightId: null,
      snackbar: false,
      samePriceFlights: {},
      flightsWithSamePriceDialog: false
    }
  },
  methods: {
    getThisDate (date) {
      const [year, month, day] = date.split('-')
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      return `${day} ${months[month - 1]} ${year}`
    },
    expand (id) {
      if (id === this.currentFlight) {
        this.show = !this.show
      } else {
        this.show = true
        this.currentFlight = id
      }
    },
    openSamePriceFlightsDialog (flights, id) {
      this.samePriceFlights = {
        flights: flights,
        relatedFlightId: id
      }
      this.flightsWithSamePriceDialog = true
    },
    book (id, relatedFlightId) {
      if (!relatedFlightId) relatedFlightId = 'parent'
      const routeData = this.$router.resolve({
        name: 'book',
        params: {
          id: id,
          search: this.$store.state.flightResults.search_id,
          relatedFlightId: relatedFlightId
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style>
.layover {
  background-color: #fff;
  position: absolute;
  padding: 3px 10px;
  left: 50%;
  transform: translate(-50%, -15px);
}

.v-data-table
/* /deep/ */
tbody

/* /deep/ */
tr:hover:not(.v-data-table__expanded__content) {
  padding-top: 5px;
  background: white !important;
}

.airline {
  font-size: 15px;
}

.date {
  z-index: 1;
  background-color: white;
  position: relative;
}

.segments {
  position: relative;
}

h5 {
  margin-top: 10px;
}

.select {
  position: relative;
  border-left: 0.1px solid #e0e0e0;
}

/*
    .price {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
    } */
.price {
  border-left: 1px solid #e0e0e0;
}

.actions {
  border-top: 0.1px solid #e0e0e0;
  background-color: #f3f3f3;
}

.stops {
  transform: rotate(90deg) translate(-10px, -50%);
}

.table {
  width: 100%;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s ease;
}
</style>
